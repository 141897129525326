import Vue from 'vue'
import VueI18n from 'vue-i18n'
import UzLatn from './lang/ru.json'
import UzCyrl from './lang/uz_cyrl.json'
import Ru from './lang/uz_latn.json'

Vue.use(VueI18n)
const locale = localStorage.getItem('lang') || 'ru' 
const messages = {
	uz_latn: UzLatn,
	ru: Ru,
    uz_cyrl : UzCyrl
};
const i18n = new VueI18n({
	locale,
	messages
});

export default i18n;