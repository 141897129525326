import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import axios from "axios";
import i18n from "@/translate";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/custom.scss";
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueMask from "v-mask";
Vue.use(VueMask);
import VueNavigationBar from "vue-navigation-bar";
Vue.config.productionTip = false;
Vue.component("date-picker", DatePicker);
Vue.component("vue-navigation-bar", VueNavigationBar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

axios.defaults.baseURL = "http://normaxtv-api.apptest.uz/";
if (window.location.href.indexOf("http://norma.uzedu.uz") > -1) {
  axios.defaults.baseURL = "http://norma-api.uzedu.uz/";
}
if (window.location.href.indexOf("https://norma.uzedu.uz") > -1) {
  axios.defaults.baseURL = "https://norma-api.uzedu.uz/";
}
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
