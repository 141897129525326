import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path : '/',
        name : 'Home',
        component : () => import('@/views/Home'),
        redirect: '/about',
        children : [
            {
                path : 'about',
                name : 'About',
                component : () => import('@/views/About')
            },
            // {
            //     path : 'qabul',
            //     name : 'Qabul',
            //     component : () => import('@/views/qabul')
            // },
            // {
            //     path : 'ota_onalar_tulovi',
            //     name : 'Ota-Onalar-tulovi',
            //     component : () => import('@/views/ota_onalar_tulovi')
            // },
            // {
            //     path : 'oqishni_kochirish_va_tiklash',
            //     name : 'Oqishni-kochirish-va-tiklash',
            //     component : () => import('@/views/oqishni_kochirish_va_tiklash')
            // },
            // {
            //     path : 'ijtimoiy_soha',
            //     name : 'Ijtimoiy-Soha',
            //     component : () => import('@/views/ijtimoiy_soha')
            // },
            // {
            //     path : 'rasmiy_hujjatlar',
            //     name : 'Rasmiy-hujjatlar',
            //     component : () => import('@/views/rasmiy_hujjatlar')
            // },
            // {
            //     path : '/qabul/1_sinfga_qabul',
            //     name : '1-sinfga-qabul',
            //     component : () => import('@/views/qabul/1_sinfga_qabul_qilish')
            // }
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({
        y: 0
    }),
    routes: routes
});
export default router